/* global graphql */
import React from 'react';
import TemplateWrapper from '../components/TemplateWrapper';
import { Link } from "gatsby";

const PrivacyPolicy = props =>
  (<TemplateWrapper location={props.location}>
    <main className="PrivacyPolicyPage">
      <section className="Section">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row PrivacyPolicyPage-container">
              <div className="col-xs-14 col-md-12 col-md-offset-1 PrivacyPolicyPage-heading">
                <h1 className="Heading Heading-d1 PrivacyPolicyPage-title">Privacy Policy</h1>
                <hr className="PrivacyPolicyPage-hr"/>
                <h3 className="Heading Heading-h3 PrivacyPolicyPage-subtitle">Last Revised: 11/9/2018</h3>
                <p className="BodyText BodyText-B2 PrivacyPolicyPage-intro">Hologram, Inc. (“Company”) is committed to protecting your privacy.  We have prepared this Privacy Policy to describe to you our practices regarding the Personal Data (as defined below) we collect from users of our website, located at <Link to="/" className="Btn BtnText BtnText-green light font-regular">[https://magic.co]</Link> (the “Site”).</p>
                <p className="BodyText BodyText-B2 PrivacyPolicyPage-intro">Questions; Contacting Company; Reporting Violations.  If you have any questions or concerns or complaints about our Privacy Policy or our data collection or processing practices, or if you want to report any security violations to us, please contact us at our mailing address below or by emailing: <a className="Btn BtnText BtnText-green light font-regular" href="mailto:privacy@magic.co">privacy@magic.co</a></p>
                <p className="BodyText BodyText-B2 PrivacyPolicyPage-address">Hologram, Inc.<br/>
                  1 N. LaSalle St, Suite 850<br/>
                  Chicago, IL 60602</p>
              </div>
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <ol>
                  <li><span>A Note to Users Outside of the United States.</span>  If you are a non-U.S. user of the Site, by visiting the Site and providing us with data, you acknowledge and agree that your Personal Data may be processed for the purposes identified in the Privacy Policy.  In addition, your Personal Data may be processed in the country in which it was collected and in other countries, including the United States, where laws regarding processing of Personal Data may be less stringent than the laws in your country.  By providing your data, you consent to such transfer.</li>
                  <li><span>Types of Data We Collect.  “Personal Data”</span> means data that allows someone to identify or contact you, including, for example, your name, address, telephone number, e-mail address, as well as any other non-public information about you that is associated with or linked to any of the foregoing data.  “Anonymous Data” means data that is not associated with or linked to your Personal Data; Anonymous Data does not, by itself, permit the identification of individual persons.  We collect Personal Data and Anonymous Data, as described below.<br/>
                    <ul className="no-bullet">
                      <li>
                        <span>(a) Information You Provide to Us.</span>
                        <ul>
                          <li>If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply.</li>
                          <li>When you post messages on the message boards of our Site, the information contained in your posting will be stored on public servers (e.g. Github) and other users will be able to see it. </li>
                          <li>When you participate in one of our surveys, we may collect additional profile information.</li>
                          <li>We also collect other types of Personal Data that you provide to us voluntarily if you contact us via e-mail regarding support for the Site. </li>
                          <li>We may also collect Personal Data at other points in our Site that state that Personal Data is being collected.</li>
                        </ul>
                      </li>
                      <li>
                        <span>(b) Information Collected via Technology.</span>
                        <ul>
                          <li><u>Log Files.</u>  As is true of most websites, we gather certain information automatically and store it in log files.  This information includes IP addresses, browser type, Internet service provider (“ISP”), referring/exit pages, operating system, date/time stamp, and clickstream data.  We use this information to analyze trends, administer the Site, track users’ movements around the Site, gather demographic information about our user base as a whole, and better tailor our Site to our users’ needs.  For example, some of the information may be collected so that when you visit the Site again, it will recognize you and the information could then be used to serve advertisements and other information appropriate to your interests. Except as noted in this Privacy Policy, we do not link this automatically-collected data to Personal Data.</li>
                          <li><u>Cookies.</u> Like many online services, we use cookies to collect information.  “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing the website.  We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site. This type of information is collected to make the Site more useful to you and to tailor the experience with us to meet your special interests and needs.</li>
                          <li><u>Pixel Tags.</u> In addition, we use <span>“Pixel Tags”</span> (also referred to as clear Gifs, Web beacons, or Web bugs).  Pixel Tags are tiny graphic images with a unique identifier, similar in function to Cookies that are used to track online movements of Web users.  In contrast to Cookies, which are stored on a user’s computer hard drive, Pixel Tags are embedded invisibly in Web pages.  Pixel Tags also allow us to send e-mail messages in a format users can read, and they tell us whether e-mails have been opened to ensure that we are sending only messages that are of interest to our users.  We may use this information to reduce or eliminate messages sent to a user.  We do not tie the information gathered by Pixel Tags to our users’ Personal Data.</li>
                          <li><u>Google Analytics.</u> We use Google Analytics to help analyze how users use the Site. Google Analytics uses Cookies to collect information such as how often users visit the Site, what pages they visit, and what other sites they used prior to coming to the Site. We use the information we get from Google Analytics only to improve our Site. Google Analytics collects only the IP address assigned to you on the date you visit the Site, rather than your name or other personally identifying information.  We do not combine the information generated through the use of Google Analytics with your Personal Data. Although Google Analytics plants a persistent Cookie on your web browser to identify you as a unique user the next time you visit the Site, the Cookie cannot be used by anyone but Google. Google’s ability to use and share information collected by Google Analytics about your visits to the Site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy.</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Use of Your Personal Data</span><br/>
                    <ul className="no-bullet">
                      <li>
                        <span>(a) General Use.</span> In general, Personal Data you submit to us is used either to respond to requests that you make, or to aid us in serving you better. We use your Personal Data in the following ways:<br/>
                        <ul>
                          <li>identify you as a user in our system;</li>
                          <li>provide improved administration of our Site;</li>
                          <li>improve the quality of experience when you interact with our Site;</li>
                          <li>send you administrative e-mail notifications, such as security or support and maintenance advisories;</li>
                          <li>respond to your inquiries related to your requests; and</li>
                          <li>send newsletters, surveys, offers, and other promotional materials related to our Site and for other marketing purposes of Company.</li>
                        </ul>
                      </li>
                      <li>
                    <span>(b) Creation of Anonymous Data.</span> We may create Anonymous Data records from Personal Data by excluding information (such as your name) that makes the data personally identifiable to you. We use this Anonymous Data to analyze request and usage patterns so that we may enhance the content of, and improve, Site navigation. We reserve the right to use Anonymous Data for any purpose and disclose Anonymous Data to third parties in our sole discretion.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Disclosure of Your Personal Data.</span> We disclose your Personal Data as described below and as described elsewhere in this Privacy Policy.
                    <ul className="no-bullet">
                      <li><span>(a) Third Party Service Providers.</span> We may share your Personal Data with third party service providers to:  provide you with the services that we offer you through our Site; to conduct quality assurance testing; to facilitate creation of accounts; to provide technical support; and/or to provide other services to the Company.  These third-party service providers are required not to use your Personal Data other than to provide the services requested by Company.</li>
                      <li><span>(b) Affiliates.</span> We may share some or all of your Personal Data with our parent company, subsidiaries, joint ventures, or other companies under a common control (<span>“Affiliates”</span>), in which case we will require our Affiliates to honor this Privacy Policy. </li>
                      <li><span>(c) Corporate Restructuring.</span> We may share some or all of your Personal Data in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets.  In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another company acquires our company, business, or assets, that company will possess the Personal Data collected by us and will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy.</li>
                      <li><span>(d) Other Disclosures.</span> Regardless of any choices you make regarding your Personal Data (as described below), Company may disclose Personal Data if it believes in good faith that such disclosure is necessary (a) in connection with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas or warrants served on Company; (c) to protect or defend the rights or property of Company or users of the Site; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Use.</li>
                    </ul>
                  </li>
                  <li><span>Third Party Websites.</span> Our Site may contain links to third party websites. When you click on a link to any other website or location, you will leave our Site and go to another site, and another entity may collect Personal Data or Anonymous Data from you. We have no control over, do not review, and cannot be responsible for, these outside websites or their content. Please be aware that the terms of this Privacy Policy do not apply to these outside websites or content, or to any collection of your Personal Data after you click on links to such outside websites. We encourage you to read the privacy policies of every website you visit.  The links to third party websites or locations are for your convenience and do not signify our endorsement of such third parties or their products, content or websites.</li>
                  <li><span>Your Choices Regarding Information.</span> You have several choices regarding the use of information on our Site:<br/>
                   <ul className="no-bullet">
                     <li><strong>(a) Email Communications.</strong>  We will periodically send you free newsletters and e-mails that directly promote the use of our Site.  When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the e-mail you receive or by contacting us directly (please see contact information below).  Despite your indicated e-mail preferences, we may send you service related communications, including notices of any updates to our Terms of Use or Privacy Policy.</li>
                     <li><strong>(b) Cookies.</strong>  If you decide at any time that you no longer wish to accept Cookies from our Site for any of the purposes described above, then you can instruct your browser, by changing its settings, to stop accepting Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your browser’s technical information. If you do not accept Cookies, however, you may not be able to use all portions of the Site or all functionality of the Site. If you have any questions about how to disable or modify Cookies, please let us know at the contact information provided below.</li>
                   </ul>
                  </li>
                  <li><strong>Changes to This Privacy Policy.</strong>  This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here magic.co/privacy and we will change the “Last Updated” date above. You should consult this Privacy Policy regularly for any changes.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </TemplateWrapper>);

export default PrivacyPolicy;

